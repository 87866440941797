import * as Sentry from '@sentry/nuxt';

const config = useRuntimeConfig();

Sentry.init({
  dsn: 'https://4b702dc76e35de6c45dbef1fb4a8593c@o4507770607173632.ingest.de.sentry.io/4507770613989456',

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: config.public.HYPTT_ENV || 'unknown',
});
